<div>
  <div class="flex flex-row">
    <div class="flex-auto">
      <div class="flex-initial">
        <h4>BOL Bulk Upload</h4>
      </div>
    </div>

    <div class="flex-initial justify-items-end content-center">
      <button mat-flat-button color="primary" (click)="promptUploadModal()">
        <mat-icon>upload</mat-icon>
        Import
      </button>
    </div>
  </div>

  <div
    *ngIf="loading"
    class="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-opacity-50 bg-gray-100 z-10"
  >
    <mat-spinner diameter="40"></mat-spinner>
  </div>

  <table mat-table [dataSource]="dataSource" class="bol-upload-log-table">
    <ng-container matColumnDef="createdAt">
      <th mat-header-cell *matHeaderCellDef>Batch</th>
      <td mat-cell *matCellDef="let element">
        {{ element.createdAt | date: 'MMM d, y, h:mm a' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="fracName">
      <th mat-header-cell *matHeaderCellDef>Frac</th>
      <td mat-cell *matCellDef="let element">
        {{ element.fracName }}
      </td>
    </ng-container>

    <ng-container matColumnDef="processingCount">
      <th mat-header-cell *matHeaderCellDef>
        <div class="text-right">Processing</div>
      </th>
      <td mat-cell *matCellDef="let element" class="text-right">
        <ng-container *ngIf="element.processingCount > 0">
          <a (click)="showLogs(element.logs, 'processing')">{{ element.processingCount }}</a>
        </ng-container>
        <ng-container *ngIf="element.processingCount === 0">0</ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="failedCount">
      <th mat-header-cell *matHeaderCellDef>
        <div class="text-right">Failed</div>
      </th>
      <td mat-cell *matCellDef="let element" class="text-right">
        <ng-container *ngIf="element.failedCount > 0">
          <a (click)="showLogs(element.logs, 'failed')">{{ element.failedCount }}</a>
        </ng-container>
        <ng-container *ngIf="element.failedCount === 0">0</ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="passedCount">
      <th mat-header-cell *matHeaderCellDef>
        <div class="text-right">Passed</div>
      </th>
      <td mat-cell *matCellDef="let element" class="text-right">
        <ng-container *ngIf="element.passedCount > 0">
          <a (click)="showLogs(element.logs, 'passed')">{{ element.passedCount }}</a>
        </ng-container>
        <ng-container *ngIf="element.passedCount === 0">0</ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="total">
      <th mat-header-cell *matHeaderCellDef>
        <div class="text-right">Total</div>
      </th>
      <td mat-cell *matCellDef="let element" class="text-right">
        <ng-container *ngIf="element.total > 0">
          <a (click)="showLogs(element.logs)">{{ element.total }}</a>
        </ng-container>
        <ng-container *ngIf="element.total === 0">0</ng-container>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <mat-paginator [length]="total" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]"> </mat-paginator>
</div>

<ng-template #uploadModal>
  <div mat-dialog-title>Upload BOL</div>

  <div mat-dialog-content>
    <div class="flex flex-col gap-2">
      <div>
        Please make sure <span class="font-bold">filename</span> is set to the BOL Number.
        <span class="text-sm font-light italic">Ex: ABC123.png or XYZ987.pdf</span>
        <br />
        <span class="text-sm font-light italic"
          >You can select single file or multiple file by holding <span class="font-bold">ctrl/cmd</span> key.</span
        >
      </div>

      <div>
        <label>Frac</label>
        <div class="border border-gray-400 p-1 rounded-md">
          <mat-select placeholder="Select Frac" [formControl]="selectedFracId">
            <mat-option>
              <ngx-mat-select-search
                placeholderLabel="Search Frac"
                noEntriesFoundLabel="No Frac found"
                [formControl]="fracSearch"
              ></ngx-mat-select-search>
            </mat-option>
            <mat-option
              *ngFor="let frac of filteredFracs$ | async; trackBy: filteredFracs$ | trackByKey: 'id'"
              [value]="frac.id"
            >
              {{ frac.jobName }}
            </mat-option>
          </mat-select>
        </div>
      </div>

      <div class="placeholder py-1">
        <ngx-file-drop
          class="placeholder-message"
          dropZoneLabel="Drop files here or  "
          (onFileDrop)="onFileDropped($event)"
          [showBrowseBtn]="true"
          multiple="true"
          accept="image/*,application/pdf"
        >
          <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
            <span class="text-sm">Drop files here or</span>
            <a
              href="#"
              class="hover:underline rounded-md text-sm font-semibold focus:outline-none px-1"
              (click)="openFileSelector()"
              target="_blank"
              >click here</a
            >
            <span class="text-sm">to upload.</span>
          </ng-template>
        </ngx-file-drop>
      </div>

      <div class="divide-y divide-gray-400 border-t border-gray-100 mb-3">
        <table class="w-full text-left table-collapse">
          <thead>
            <th class="text-sm font-bold text-gray-600 pl-1 border-b border-gray-400 w-auto">No.</th>
            <th class="text-sm font-bold text-gray-600 pl-1 border-b border-gray-400 w-auto">File Name</th>
            <th class="text-sm font-bold text-gray-600 pl-1 border-b border-gray-400 w-auto">BOL Number</th>
            <th class="text-sm font-bold text-gray-600 pl-1 border-b border-gray-400 w-auto">File Size</th>
            <th class="text-sm font-bold text-gray-600 pl-1 border-b border-gray-400 w-auto">File Type</th>
            <th class="text-sm font-bold text-gray-600 pl-1 border-b border-gray-400 w-auto">Remove</th>
          </thead>
          <tbody>
            <ng-container *ngIf="this.listOfFiles.length > 0; else noFiles">
              <tr
                class="hover:bg-gray-100"
                *ngFor="let file of this.listOfFiles; let i = index; trackBy: this.listOfFiles | trackByKey: 'fileName'"
              >
                <td class="pl-1 border-b border-gray-400">{{ i + 1 }}</td>
                <td class="pl-1 border-b border-gray-400">{{ file.fileName }}</td>
                <td class="pl-1 border-b border-gray-400">
                  <strong>{{ file.bolNumber }}</strong>
                </td>
                <td class="pl-1 border-b border-gray-400">{{ file.fileSize | fileSize }}</td>
                <td class="pl-1 border-b border-gray-400">{{ file.fileType }}</td>
                <td class="pl-1 border-b border-gray-400">
                  <button mat-icon-button color="warn" (click)="removeFileFromList(i)">
                    <mat-icon class="menu-icon">delete</mat-icon>
                  </button>
                </td>
              </tr>
            </ng-container>
            <ng-template #noFiles>
              <tr class="bg-gray-100">
                <td class="pl-1 border-b border-gray-400 text-center" colspan="6">No file selected.</td>
              </tr>
            </ng-template>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <div mat-dialog-actions class="flex justify-between">
    <div>
      <button mat-raised-button matDialogClose>Nevermind</button>
    </div>
    <div>
      <button
        mat-raised-button
        color="primary"
        (click)="submitUploadBol()"
        [disabled]="!selectedFracId.value || listOfFiles.length === 0"
      >
        Confirm
      </button>
    </div>
  </div>
</ng-template>
