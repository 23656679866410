<sa-top-nav></sa-top-nav>

<div class="outer-container" fxLayout="row" fxLayoutAlign="center">
  <div class="bulk-load-upload-wrap">
    <h2>
      Bulk Upload
    </h2>

    <sa-subnav [subnavMenu]="subnavMenu" (onTabSwitch)="activeMenu = $event"></sa-subnav>

    <div class="content">
      <div class="load" *ngIf="activeMenu === subnavLabels.load">
        <div>
          <div class="flex flex-row">
            <div class="flex-auto">
              <div class="flex-initial">
                <h4>You can bulk upload the load based on the template provided.</h4>
              </div>
            </div>
            <div class="flex-initial justify-items-end content-center" *saFeatureFlag="'showBulkLoadUploadMenu'">
              <csvbox-button [licenseKey]="licenseKey" [user]="user" [onImport]="onData.bind(this)" class="mx-2">
                <button mat-flat-button color="primary">
                  <mat-icon>upload</mat-icon>
                  Import
                </button>
              </csvbox-button>
            </div>
          </div>

          <div
            *ngIf="loading"
            class="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-opacity-50 bg-gray-100 z-10"
          >
            <mat-spinner diameter="40"></mat-spinner>
          </div>

          <table mat-table [dataSource]="dataSource" class="">
            <ng-container matColumnDef="id">
              <th mat-header-cell *matHeaderCellDef>ID</th>
              <td mat-cell *matCellDef="let element">{{ element.id }}</td>
            </ng-container>

            <ng-container matColumnDef="filename">
              <th mat-header-cell *matHeaderCellDef>Filename</th>
              <td mat-cell *matCellDef="let element">{{ element.filename }}</td>
            </ng-container>

            <ng-container matColumnDef="date">
              <th mat-header-cell *matHeaderCellDef>Date</th>
              <td mat-cell *matCellDef="let element">
                {{ element.created_at | date: 'MMM d, y, h:mm a' }}
              </td>
            </ng-container>

            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef>Status</th>
              <td mat-cell *matCellDef="let element">
                <a (click)="showRemarks(element)">{{ element.status }}</a>
                &nbsp;
                <a [routerLink]="['/bulk-load-uploaded', element.id]">Imported orders</a>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>

          <mat-paginator [length]="total" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]"> </mat-paginator>
        </div>
      </div>

      <div class="load" *ngIf="activeMenu === subnavLabels.bolUpload">
        <sa-bulk-bol-upload></sa-bulk-bol-upload>
        <!--        <div>BOL upload here</div>-->
      </div>
    </div>
  </div>
</div>
