import { AgoPipe } from './ago.pipe';
import { UntilPipe } from './until.pipe';
import { PoundsToTonsPipe } from './pounds-to-tons';
import { DateOrEmDashPipe } from './date-or-em-dash';
import { ProgressiveRenderPipe } from './progressive-render.pipe';
import { CurrencyInPenniesPipe } from './currency-in-pennies.pipe';
import { SPrefixPipe } from '../../pipes/s-prefix.pipe';
import { DashIfNothingPipe } from './dash-if-nothing.pipe';
import { FileSizePipe } from '../../pipes/file-size.pipe';

export const pipes = [
  AgoPipe,
  UntilPipe,
  PoundsToTonsPipe,
  DateOrEmDashPipe,
  DashIfNothingPipe,
  ProgressiveRenderPipe,
  CurrencyInPenniesPipe,
  SPrefixPipe,
  FileSizePipe,
];
