import { AfterViewInit, ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BulkBolUploadLog, BulkBolUploadLogByGroup, BulkBolUploadLogResponse } from '../bulk-load-upload.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { combineLatest, Observable, Subject, throwError } from 'rxjs';
import { environment } from '~environments/environment';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { Frac } from '~lmo/models/frac.model';
import { LmoFracsService } from '~lmo/services';
import { FormControl } from '@angular/forms';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { MatPaginator } from '@angular/material/paginator';
import { BulkBolUploadLogComponent } from '../bulk-bol-upload-log/bulk-bol-upload-log.component';

interface FileReadyToUploadStatus {
  fileName: string;
  fileSize: number;
  fileType: string;
  bolNumber: string;
}

@Component({
  selector: 'sa-bulk-bol-upload',
  templateUrl: './bulk-bol-upload.component.html',
  styleUrls: ['./bulk-bol-upload.component.scss'],
})
export class BulkBolUploadComponent implements OnInit, AfterViewInit {
  @ViewChild('uploadModal', { static: true }) private uploadModal: TemplateRef<any>;
  public loading: boolean;
  public uploading: boolean;
  dataSource: BulkBolUploadLogByGroup[];
  displayedColumns: string[] = ['createdAt', 'fracName', 'processingCount', 'failedCount', 'passedCount', 'total'];
  total: number;

  private destroy$ = new Subject();

  public listOfFiles: FileReadyToUploadStatus[] = [];
  public fracList$: Observable<Frac[]> = new Observable<Frac[]>();
  public selectedFracId: FormControl = new FormControl();
  public fracSearch = new FormControl(null);
  public filteredFracs$: Observable<Frac[]>;

  public formData = new FormData();

  constructor(
    private fracService: LmoFracsService,
    private http: HttpClient,
    private snackBar: MatSnackBar,
    private matDialog: MatDialog,
    private cdr: ChangeDetectorRef,
  ) {}

  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngOnInit(): void {
    this.fracList$ = this.fracService.fracs$;
    this.filteredFracs$ = combineLatest([this.fracList$, this.fracSearch.valueChanges.pipe(startWith(''))]).pipe(
      map(([fracs, searchFilter]) => {
        if (!searchFilter) {
          return fracs;
        }

        const searchFilterValue = searchFilter.toLowerCase();
        return fracs.filter((option) => option.jobName.toLowerCase().includes(searchFilterValue));
      }),
      takeUntil(this.destroy$),
    );
  }

  ngAfterViewInit(): void {
    this.fetchLogs();

    this.paginator.page.subscribe(() => {
      this.fetchLogs();
    });
  }

  fetchLogs() {
    this.loading = true;
    this.cdr.detectChanges();
    this.http
      .get<BulkBolUploadLogResponse>(`${environment.api}/bulk_bol_upload_logs`, {
        params: {
          page: `${this.paginator.pageIndex}`,
          pageSize: `${this.paginator.pageSize}`,
        },
      })
      .subscribe(
        (data: BulkBolUploadLogResponse) => {
          this.dataSource = data.data;
          this.total = data.total;
          this.loading = false;
        },
        (logs) => {
          this.loading = false;
        },
      );
  }

  async submitUploadBol() {
    this.uploading = true;
    const fracId = this.selectedFracId.value;

    if (this.listOfFiles.length > 0) {
      this.uploadBOLs(fracId).subscribe(
        (response) => {
          this.uploading = false;
          this.matDialog.closeAll();
          this.snackBar.open('Files uploaded successfully', 'Close', { duration: 3000 });
          this.listOfFiles = [];
          this.formData = new FormData();
          this.fetchLogs();
        },
        (error) => {
          this.uploading = false;
          this.snackBar.open('Error uploading files', 'Close', { duration: 3000 });
          this.fetchLogs();
        },
      );
    } else {
      this.uploading = false;
      this.snackBar.open('No files to upload', 'Close', { duration: 3000 });
      this.fetchLogs();
    }
  }

  promptUploadModal() {
    const config = { maxHeight: '80vh', maxWidth: '968px', width: '80%' };
    this.matDialog.open(this.uploadModal, config);
  }

  uploadBOLs(fracId: string): Observable<any[]> {
    if (this.listOfFiles && this.listOfFiles.length > 0) {
      return this.http.post<any[]>(`${environment.api}/orders/bulk/bol_upload/` + fracId, this.formData);
    } else {
      return throwError('Files were not set, please try again');
    }
  }

  private async getFileFromEntry(fileEntry: FileSystemFileEntry): Promise<File> {
    return new Promise((resolve, reject) => {
      fileEntry.file((file: File) => {
        resolve(file);
      });
    });
  }

  async onFileDropped(files: NgxFileDropEntry[]) {
    let containDuplicates = false;

    for (const droppedFile of files) {
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        const file = await this.getFileFromEntry(fileEntry);

        if (this.listOfFiles.some((f) => f.fileName === file.name)) {
          containDuplicates = true;
          continue;
        }

        this.formData.append('files', file, droppedFile.relativePath);
        this.listOfFiles.push({
          fileName: file.name,
          fileSize: file.size,
          fileType: file.type,
          bolNumber: this.getBolNumberFromFilename(file.name),
        });
      }
    }

    if (containDuplicates) {
      this.snackBar.open('Files with the same name excluded.', 'Close', { duration: 3000 });
    }
  }

  getBolNumberFromFilename(filename: string): string {
    return filename.replace(/\.[^/.]+$/, '');
  }

  removeFileFromList(index: number): void {
    if (index !== -1) {
      this.listOfFiles.splice(index, 1);

      const newFormData = new FormData();
      for (const [key, value] of this.formData.getAll('files').entries()) {
        if (value instanceof File && key !== index) {
          newFormData.append('files', value, value.name);
        }
      }
      this.formData = newFormData;
    }
  }

  showLogs(logs: BulkBolUploadLog[], status?: string) {
    if (status) {
      logs = logs.filter((log) => log.status === status);
    }

    const config = { data: logs, maxHeight: '80vh', maxWidth: '1s00px', width: '90%', disableClose: false };
    this.matDialog.open(BulkBolUploadLogComponent, config);
  }
}
