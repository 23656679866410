<ng-container *ngIf="showSection == 1">
  <div class="pending-order-detail-component" *ngIf="!loading && isOrder()">
    <div class="back-wrap" (click)="goBack()">
      <i class="material-icons">arrow_back</i>
    </div>
    <div class="header-wrap">
      <div class="name-details">
        <h1>{{ selectedFrac | fracName }}</h1>
        <h2>
          {{ selectedOrder?.saUniqueId }}<br />
          <span class="sub-header">Sequence Number: {{selectedOrder?.loadNumber}}</span>
        </h2>
      </div>
      <div class="decline-order">
        <button mat-stroked-button (click)="declineOrder()">Decline Order</button>
      </div>
    </div>

    <form *ngIf="addForm" [formGroup]="addForm" (submit)="submit()" autocomplete="off">
      <div class="content-wrap">
        <ng-container>
          <div class="boost-percent" *ngIf="selectedOrder?.boostPercent > 0">
            <div class="bold">Get {{ selectedOrder?.boostPercent }}% Boost Pricing</div>
            <div>
              Bring a new driver online for this load to get a {{ selectedOrder?.boostPercent }}% bonus. Bonus does not
              apply to drivers who are already working for this frac.
            </div>
          </div>
        </ng-container>

        <div class="order-details-section">
          <h3>Order Details</h3>

          <div class="item-row">
            <div class="label">
              Loader
            </div>
            <div class="value-wrap">{{ selectedOrder | orderPickupSiteName }}</div>
          </div>

          <div class="item-row">
            <div class="label">
              PO Number
            </div>
            <div class="value-wrap">{{ selectedOrder?.poNumber }}</div>
          </div>

          <div class="item-row">
            <div class="label">
              Appointment Time
            </div>
            <div class="value-wrap">{{ selectedOrder?.startTimestamp | date: 'shortTime' }}</div>
          </div>

          <div class="item-row">
            <div class="label">
              Payload
            </div>
            <div class="value-wrap">{{ selectedOrder?.mesh.type }} Mesh</div>
          </div>

          <div class="item-row">
            <div class="label">
              Quantity
            </div>
            <div class="value-wrap">{{ selectedOrder?.loadWeight }} lbs</div>
          </div>

          <div *saFeatureFlag="'orderTrailerType'" class="item-row">
            <div *ngIf="selectedOrder?.trailerType" class="label">
              Trailer Type
            </div>
            <div class="value-wrap">{{ selectedOrder?.trailerType?.trailerTypeDisplayName }}</div>
          </div>
        </div>

        <div class="order-details-section">
          <h3>Required</h3>

          <div class="item-row" id="pending-driver">
            <div class="label">
              Driver
            </div>
            <mat-form-field (click)="selectDriver()" saAutomatedSelector="dispatcher_pending_order__select_driver">
              <input
                matInput
                placeholder="Driver"
                type="text"
                autocomplete="off"
                [formControl]="addForm.controls['driver']"
              />
            </mat-form-field>
          </div>

          <div class="item-row" id="pending-truck">
            <div class="label">
              Truck
            </div>
            <mat-form-field>
              <input
                type="text"
                placeholder="Truck"
                matInput
                [formControl]="addForm.controls['truck']"
                [matAutocomplete]="auto"
                saAutomatedSelector="dispatcher_pending_order__truck"
              />
              <mat-autocomplete
                #auto="matAutocomplete"
                (closed)="clearTruck()"
                (optionSelected)="onTruckSelected($event.option.value)"
              >
                <mat-option *ngFor="let option of truckService.truckList$ | async" [value]="option">
                  {{ option.name }} <span *ngIf="option.isInUse">(In Use)</span>
                </mat-option>
                <mat-option [value]="addForm.controls['truck'].value" *ngIf="checkForAddTruck$() | async">
                  <span class="add-truck-option" saAutomatedSelector="dispatcher_pending_order__new_truck"
                    >+ Add New Truck</span
                  >
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>

          <div class="item-row" *ngIf="addForm.get('box1Id')" id="pending-box-1">
            <div class="label">
              Box 1 ID
            </div>
            <mat-form-field>
              <input
                type="text"
                placeholder="BOX 1 ID"
                matInput
                [formControl]="addForm.controls['box1Id']"
                saAutomatedSelector="dispatcher_pending_order__box_1_id"
              />
            </mat-form-field>
          </div>

          <div class="item-row" *ngIf="addForm.get('box2Id')" id="pending-box-2">
            <div class="label">
              Box 2 ID
            </div>
            <mat-form-field>
              <input type="text" placeholder="BOX 2 ID" matInput [formControl]="addForm.controls['box2Id']" />
            </mat-form-field>
          </div>

          <div class="item-row">
            <div class="label">
              Loaded
            </div>
            <mat-form-field>
              <mat-select placeholder="Loaded" [formControl]="addForm.controls['loaded']">
                <mat-option [value]="true">
                  Yes
                </mat-option>
                <mat-option [value]="false">
                  No
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="item-row" id="pending-hos">
            <div class="label">
              Hours Remaining on Shift
            </div>
            <mat-form-field>
              <mat-select
                placeholder="Please Select"
                [formControl]="addForm.controls['reuse']"
                saAutomatedSelector="dispatcher_pending_order__hos_select"
              >
                <mat-option [value]="0" saAutomatedSelector="dispatcher_pending_order__hos_no_reuse">
                  Do Not Reuse Driver
                </mat-option>
                <mat-option *ngFor="let number of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14]" [value]="number">
                  {{ number }}
                  <span *ngIf="number == 1">Hour</span>
                  <span *ngIf="number > 1">Hours</span>
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="item-row" id="pending-trailer" *ngIf="selectedOrder?.lmo.isTrailerNumberRequired">
            <div class="label">Trailer #</div>
            <mat-form-field>
              <input
                type="text"
                placeholder="Trailer"
                matInput
                [formControl]="addForm.controls['trailer']"
                [matAutocomplete]="trailer"
                [readonly]="isTrailerSelectionDisabled()"
                [matTooltip]="getTooltipText()"
              />
              <span matSuffix *ngIf="selectedTrailer?.trailerTypeName" class="trailer-type-selected">
                | {{ selectedTrailer.trailerTypeName }}
              </span>
              <mat-autocomplete
                #trailer="matAutocomplete"
                (closed)="clearTrailer()"
                (optionSelected)="onTrailerSelected($event.option.value)"
              >
                <ng-container *ngIf="trailersOptions?.length > 0">
                  <mat-option *ngFor="let option of trailersOptions" [value]="option.id">
                    <span>{{ option.name }}<span *ngIf="option?.inUse">(In Use)</span></span>
                    <span class="trailer-type-option" *ngIf="option.trailerTypeName"
                      >| {{ option.trailerTypeName }}</span
                    >
                  </mat-option>
                  <mat-option [value]="addForm.controls['trailer'].value" *ngIf="checkForAddTrailer()">
                    + Add New Trailer
                  </mat-option>
                </ng-container>
              </mat-autocomplete>
            </mat-form-field>
          </div>

          <ng-container *ngIf="isTrailerTypeAssetStatusMandatory">
            <ng-container *saIsBrokerage>
              <div *saFeatureFlag="'brokerOrderTrailerTypes'" class="item-row">
                <div class="label">
                  Trailer Type
                </div>
                <mat-form-field>
                  <mat-select
                    [placeholder]="addForm.controls['brokerTrailerTypeId'].value === null ? 'Not Set' : 'Trailer Type'"
                    [formControl]="addForm.controls['brokerTrailerTypeId']"
                    required
                  >
                    <mat-option *ngFor="let trailerType of trailerTypes.value" [value]="trailerType.id">
                      {{ trailerType.trailerTypeName }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </ng-container>

            <ng-container *saIsBrokerage>
              <div class="item-row">
                <div class="label">
                  Asset Status
                </div>
                <mat-form-field>
                  <mat-select
                    [placeholder]="addForm.controls['assetStatus'].value === null ? 'Not Set' : 'Asset Status'"
                    [formControl]="addForm.controls['assetStatus']"
                    required
                  >
                    <mat-option *ngFor="let assetStatus of assetStatuses.value" [value]="assetStatus">
                      {{ assetStatus }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </ng-container>
          </ng-container>
        </div>

        <div class="order-details-section">
          <h3>Optional</h3>

          <ng-container *ngIf="!isTrailerTypeAssetStatusMandatory">
            <ng-container *saIsBrokerage>
              <div *saFeatureFlag="'brokerOrderTrailerTypes'" class="item-row">
                <div class="label">
                  Trailer Type
                </div>
                <mat-form-field>
                  <mat-select
                    [placeholder]="addForm.controls['brokerTrailerTypeId'].value === null ? 'Not Set' : 'Trailer Type'"
                    [formControl]="addForm.controls['brokerTrailerTypeId']"
                  >
                    <mat-option *ngFor="let trailerType of trailerTypes.value" [value]="trailerType.id">
                      {{ trailerType.trailerTypeName }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </ng-container>

            <ng-container *saIsBrokerage>
              <div class="item-row">
                <div class="label">
                  Asset Status
                </div>
                <mat-form-field>
                  <mat-select
                    [placeholder]="addForm.controls['assetStatus'].value === null ? 'Not Set' : 'Asset Status'"
                    [formControl]="addForm.controls['assetStatus']"
                  >
                    <mat-option *ngFor="let assetStatus of assetStatuses.value" [value]="assetStatus">
                      {{ assetStatus }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </ng-container>
          </ng-container>

          <div class="item-row" id="pending-trailer" *ngIf="selectedOrder?.lmo.isTrailerNumberRequired == false">
            <div class="label">Trailer #</div>
            <mat-form-field>
              <input
                type="text"
                placeholder="Trailer"
                matInput
                [formControl]="addForm.controls['trailer']"
                [matAutocomplete]="trailer"
                [readonly]="isTrailerSelectionDisabled()"
                [matTooltip]="getTooltipText()"
              />
              <span matSuffix *ngIf="selectedTrailer?.trailerTypeName" class="trailer-type-selected">
                | {{ selectedTrailer.trailerTypeName }}
              </span>
              <mat-autocomplete
                #trailer="matAutocomplete"
                (closed)="clearTrailer()"
                (optionSelected)="onTrailerSelected($event.option.value)"
              >
                <ng-container *ngIf="trailersOptions?.length > 0">
                  <mat-option *ngFor="let option of trailersOptions" [value]="option.id">
                    <span>{{ option.name }}<span *ngIf="option?.inUse">(In Use)</span></span>
                    <span class="trailer-type-option" *ngIf="option.trailerTypeName"
                      >| {{ option.trailerTypeName }}</span
                    >
                  </mat-option>
                </ng-container>
                <mat-option [value]="addForm.controls['trailer'].value" *ngIf="checkForAddTrailer()">
                  + Add New Trailer
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>

          <div class="item-row" id="pending-ticket-number">
            <div class="label">
              Ticket Number
            </div>
            <mat-form-field>
              <input
                matInput
                placeholder="Ticket Number"
                type="text"
                autocomplete="off"
                [formControl]="addForm.controls['ticketNumber']"
              />
            </mat-form-field>
          </div>

          <div class="item-row" id="pending-description">
            <div class="label">
              Description
            </div>
            <mat-form-field>
              <textarea
                matInput
                placeholder="Description"
                type="text"
                autocomplete="off"
                [formControl]="addForm.controls['description']"
              ></textarea>
            </mat-form-field>
          </div>
          <ng-container *saFeatureFlag="'preloadDispatch'">
            <div class="item-row" *ngIf="isLoHi">
              <div class="label">
                Preload
              </div>
              <div class="flex flex-col">
                <mat-slide-toggle [formControl]="addForm.controls['lohiPreload']"></mat-slide-toggle>
                <mat-hint class="text-sm">LoHi only, must be on shift</mat-hint>
              </div>
            </div>
          </ng-container>
        </div>
        <div class="button-wrap">
          <button
            type="submit"
            [disabled]="dispatchInProgress"
            saAutomatedSelector="dispatcher_pending_order__dispatch_driver"
          >
            Dispatch Driver
            <mat-spinner [diameter]="20" *ngIf="dispatchInProgress"></mat-spinner>
          </button>
        </div>
        <div
          class="order-details-section"
          *ngIf="selectedPurchaseOrder && selectedPurchaseOrder.uploadedFiles && selectedPurchaseOrder.uploadedFiles.length > 0"
        >
          <h3>PO Attachments</h3>
          <mat-grid-list cols="2" rowHeight="3:2">
            <mat-grid-tile
              *ngFor="let file of selectedPurchaseOrder.uploadedFiles; trackBy: selectedPurchaseOrder.uploadedFiles | trackByKey: 'id'"
            >
              <div class="image-preview">
                <img
                  [src]="file.url"
                  *ngIf="isImage(file.fileName); else noPreview"
                  (click)="openInWindow(file.url)"
                  alt="purchase order details"
                />
                <ng-template #noPreview>
                  <div class="preview-placeholder" (click)="openInWindow(file.url)">
                    <h3>{{ file.fileName }}</h3>
                  </div>
                </ng-template>
              </div>
            </mat-grid-tile>
          </mat-grid-list>
        </div>
      </div>
      <sa-order-prediction *ngIf="userService.isShaleappsEmail()"></sa-order-prediction>
    </form>
  </div>
  <div class="pending-order-detail-component" *ngIf="!loading && !isOrder()">
    <div class="back-wrap" (click)="goBack()">
      <i class="material-icons">arrow_back</i>
    </div>
    <div class="header-wrap">
      <div class="name-details">
        <h1>Order Unavailable</h1>
        <h2 *ngIf="selectedOrder?.isGone">This order is no longer available</h2>
      </div>
    </div>
  </div>
  <div class="spin-wrap" *ngIf="loading">
    <mat-spinner [diameter]="50"></mat-spinner>
    <h3>Loading Data...</h3>
  </div>
</ng-container>
<ng-container *ngIf="showSection === 2">
  <sa-add-driver
    [selectedOrder]="selectedOrder"
    [selectedFrac]="selectedFrac"
    (onGoBack)="onBack($event)"
    (reassignAndCompleteCurrentLoad)="reassignAndCompleteCurrentLoad($event)"
    (onSelectDriver)="setDriver($event)"
  >
  </sa-add-driver>
</ng-container>

<ng-container *ngIf="showSection === 3">
  <sa-add-loader
    [selectedFrac]="selectedFrac"
    [selectedOrder]="selectedOrder"
    (onGoBack)="showSection = $event"
    (onSelectLoader)="setLoader($event)"
  >
  </sa-add-loader>
</ng-container>
