import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';

import { MaterialModule } from './material-module/material.module';

import { UIComponentsModule } from './ui-components/ui-components.module';
import { DialogComponent } from './ui-components/dialog/dialog.component';
import { ConfirmDialogComponent } from './ui-components/confirm-dialog/confirm-dialog.component';
import { WellComponent } from './wells/well.component';
import { MineCreationComponent } from './wells/mine-creation/mine-creation.component';
import { StorageCreationComponent } from './wells/storage-creation/storage-creation.component';
import { MineSelectionComponent } from './wells/mine-selection/mine-selection.component';
import { WellCreationComponent } from './wells/well-creation/well-creation.component';
import { ClusterChoiceDialogComponent } from './wells/clustering/cluster-choice-dialog.component';
import { TruckingComponent } from './trucking/trucking.component';
import { VendorContractEditComponent } from './trucking/vendor-contract-edit.component';

import { AuthGuard } from './services/auth-guard';
import { AuthService } from './services/auth.service';
import { CrudService } from './services/crud.service';
import { UserService } from './services/user.service';
import { LocalStorageService } from './services/local-storage.service';
import { environment } from '../environments/environment';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { SubnavService } from './services/subnav.service';
import { MapService } from './services/map.service';

import { AccountApiService } from './services/api/account.api.service';
import { UserApiService } from './services/api/user.api.service';
import { WaypointApiService } from './services/api/waypoint.api.service';
import { SiteApiService } from './services/api/site.api.service';
import { GlobalSearchApiService } from './services/api/global-search.api.service';
import { SessionApiService } from './services/api/session.api.service';
import { SessionService } from './services/session.service';
import { WellApiService } from './services/api/well.api.service';
import { MineApiService } from './services/api/mine.api.service';
import { ConstantsApiService } from './services/api/constants.api.service';
import { OrderApiService } from './services/api/order.api.service';
import { TruckingApiService } from './services/api/trucking.api.service';
import { SubcarrierApiService } from './services/api/subcarrier.api.service';

import { DatePipe } from '@angular/common';
import { TooltipTimestampPipe } from './pipes/tooltip-timestamp.pipe';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { MapComponent } from './map/map.component';
import { SettingsComponent } from './settings/settings.component';
import { SignupComponent } from './invite/signup.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { DriverSettingsComponent } from './settings/driver-settings.component';
import { UserSettingsComponent } from './settings/user-settings.component';
import { InviteComponent } from './settings/invite/invite.component';
import { SearchApiService } from './services/api/search.api.service';
import { ChoiceDialogComponent } from './ui-components/choice-dialog/choice-dialog.component';
import { ReassignDialogComponent } from './ui-components/reassign-dialog/reassign-dialog.component';
import { DeclineDialogComponent } from './ui-components/decline-dialog/decline-dialog.component';
import { StoreService } from './services/store.service';
import { MapUtilService } from './services/map-util.service';
import { CompletedOrdersComponent } from './completed-orders/completed-orders.component';
import { CompletedOrderDetailComponent } from './completed-orders/completed-order-detail/completed-order-detail.component';
import { OrderTableComponent } from './completed-orders/order-table/order-table.component';
import { TicketDialogComponent } from './completed-orders/ticket-dialog/ticket-dialog.component';
import { FileUploadService } from './services/api/file-upload.service';
import { EmailSettingsComponent } from './email-settings/email-settings.component';
import { EmailSettingsV2Component } from './email-settings-v2/email-settings-v2.component';
import { EmailDetailsComponent } from './email-settings/email-details/email-details.component';
import { EmailFormComponent } from './email-settings/email-form/email-form.component';
import { EmailFormV2Component } from './email-settings-v2/email-form-v2/email-form-v2.component';
import { NumberDecimalPipe } from './pipes/number-decimal.pipe';
import { MinesComponent } from './mines/mines.component';
import { BulkDeclineDialogComponent } from './ui-components/bulk-decline-dialog/bulk-decline-dialog.component';
import { ReasonDialogComponent } from './completed-orders/reason-dialog/reason-dialog.component';
import { WellService } from './services/well.service';
import { CancelDialogComponent } from './completed-orders/cancel-dialog/cancel-dialog.component';
import { TokenInterceptorService } from './services/token-interceptor.service';
import { FeatureFlagService } from './services/feature-flag.service';
import { VersioningComponent } from './versioning/versioning.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { RunboardSummaryComponent } from './runboard-summary/runboard-summary.component';
import { HelperService } from './services/helper.service';
import { RunboardDriverOptionsComponent } from './runboard-driver-options/runboard-driver-options.component';
import { CacheInterceptorService } from './services/cache-interceptor.service';
import { NotificationPreferencesComponent } from './notification-preferences/notification-preferences.component';
import { ReassignTruckComponent } from './ui-components/reassign-truck/reassign-truck.component';
import { SafePipe } from './pipes/safe.pipe';
import { NgxFileDropModule } from 'ngx-file-drop';
import { OnlyEnvDirective } from './only-env.directive';
import { StageUrlIndicatorComponent } from './stage-url-indicator/stage-url-indicator.component';
import { ValidateRouteService } from '~services/validate-route.service';
import { UserGroupComponent } from './settings/user-group/user-group.component';
import { AddUserGroupComponent } from './settings/add-user-group/add-user-group.component';
import { UnsubscribeComponent } from './unsubscribe/unsubscribe.component';
import { AdminGuard } from '~services/admin-guard';
import { BulkExportComponent } from './bulk-export/bulk-export.component';
import { FracCreationMapComponent } from './wells/frac-creation-map/frac-creation-map.component';
import { VersionHeaderInterceptorService } from '~services/version-header-interceptor.service';
import { DispatcherModule } from '~dispatcher/dispatcher.module';
import { LogisticsRunboardDriverOptionsComponent } from './logistics-runboard-driver-options/logistics-runboard-driver-options.component';
import { LogisticsRunboardSummaryComponent } from './logistics-runboard-summary/logistics-runboard-summary.component';
import { DispatcherVideoTrainingComponent } from './dispatcher-video-training/dispatcher-video-training.component';
import { EmailAnalyticsComponent } from './email-settings/email-analytics/email-analytics.component';
import { TooltipModule } from 'ng2-tooltip-directive';
import { ConfigValuesService } from './services/config-values.service';
import { AddChargeDialogComponent } from './completed-orders/add-charge-dialog/add-charge-dialog.component';
import { SigninWithCustomTokenComponent } from './signin-with-custom-token/signin-with-custom-token.component';
import { OrderUniqueIdSearchComponent } from './order-unique-id-search/order-unique-id-search.component';
import { DispatcherOrderLandingComponent } from './dispatcher-order-landing/dispatcher-order-landing.component';
import { RtiDemoComponent } from './rti-demo/rti-demo.component';
import { LoaderAnalyticsDashboardComponent } from './email-settings/loader-analytics-dashboard/loader-analytics-dashboard.component';
import { BillingOrdersComponent } from './completed-orders/billing-orders/billing-orders.component';
import { AllFracOrderTableComponent } from './completed-orders/all-frac-order-table/all-frac-order-table.component';
import { StartedAtInterceptorService } from './started-at-interceptor.service';
import { SwitchVendorContractComponent } from './completed-orders/switch-vendor-contract/switch-vendor-contract.component';
import { ConfirmActionDialogComponent } from './completed-orders/confirm-action-dialog/confirm-action-dialog.component';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { VendorContractCreationComponent } from './trucking/vendor-contract-creation.component';
import { CustomRouteReuseStrategy } from './route-reuse';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { UpdatePasswordComponent } from './update-password/update-password.component';
import { EmailDetailsV2Component } from './email-settings-v2/email-details-v2/email-details-v2.component';
import { ConfirmUpdateStageDialogComponent } from './ui-components/confirm-update-stage-dialog/confirm-update-stage-dialog.component';
import { SigninWithCustomTokenLohiComponent } from './signin-with-custom-token-lohi/signin-with-custom-token-lohi.component';
import { DynamicPricingComponent } from './dynamic-pricing/dynamic-pricing.component';
import { ChartModule } from 'angular-highcharts';
import { DynamicPricingDialogComponent } from './dynamic-pricing/dynamic-pricing-dialog/dynamic-pricing-dialog.component';
import { CrewsComponent } from './settings/crews/crews.component';
import { AuthInterceptorService } from '~services/auth-interceptor.service';
import { StorageSupplementalMeshUpdateComponent } from './wells/storage-supplemental-mesh-update/storage-supplemental-mesh-update.component';
import { TruckingListTableComponent } from './trucking/components/trucking-list-table.component';
import { DistributionCenterCreationComponent } from './distribution-centers/distribution-center-creation/distribution-center-creation.component';
import { DCStorageSupplementalMeshUpdateComponent } from './wells/dc-storage-supplemental-mesh-update/dc-storage-supplemental-mesh-update.component';
import { DistributionCenterStorageCreationComponent } from './wells/dc-storage-creation/dc-storage-creation.component';
import { LoaderAnalyticsDashboardV2Component } from './email-settings-v2/loader-analytics-dashboard-v2/loader-analytics-dashboard-v2/loader-analytics-dashboard-v2.component';
import { LoadsOnDateComponent } from './email-settings-v2/loader-analytics-dashboard-v2/loads-on-date/loads-on-date.component';
import { InTimezonePipe } from './pipes/in-timezone.pipe';
import { MatchesForLoadComponent } from './email-settings-v2/loader-analytics-dashboard-v2/matches-for-load/matches-for-load.component';
import { UploadAnalyticsFileComponent } from './email-settings-v2/loader-analytics-dashboard-v2/loader-analytics-dashboard-v2/upload-analytics-file/upload-analytics-file.component';
import { UnsubscribePoComponent } from './unsubscribe/unsubscribe-po/unsubscribe-po.component';
import { DynamicPricingForecastComponent } from './dynamic-pricing/dynamic-pricing-forecast/dynamic-pricing-forecast.component';
import { UploadGrGiFileComponent } from './email-settings-v2/loader-analytics-dashboard-v2/loader-analytics-dashboard-v2/upload-gr-gi-file/upload-gr-gi-file.component';
import { LoadsForPurchaseOrderComponent } from './email-settings-v2/loader-analytics-dashboard-v2/loads-for-purchase-order/loads-for-purchase-order.component';
import { SubcarrierComponent } from './subcarrier/subcarrier.component';
import { CarrierComponent } from './subcarrier/carrier/carrier.component';
import { ExportLoaderAnalyticsDataComponent } from './email-settings-v2/loader-analytics-dashboard-v2/loader-analytics-dashboard-v2/export-loader-analytics-data/export-loader-analytics-data.component';
import { CrewDataDashboardComponent } from './email-settings-v2/crew-data-dashboard/crew-data-dashboard.component';
import { UploadCrewDataFileComponent } from './email-settings-v2/crew-data-dashboard/upload-crew-data-file/upload-crew-data-file.component';
import { CrewDataOnDateComponent } from './email-settings-v2/crew-data-dashboard/crew-data-on-date/crew-data-on-date.component';
import { LmoLoaderNameMappingComponent } from './email-settings-v2/lmo-loader-name-mapping/lmo-loader-name-mapping.component';
import { LmoLoaderNameMappingFormComponent } from './email-settings-v2/lmo-loader-name-mapping/lmo-loader-name-mapping-form/lmo-loader-name-mapping-form.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { LmoPurchaseOrderTimelineComponent } from '~lmo/components/lmo-purchase-orders/lmo-purchase-order-timeline/lmo-purchase-order-timeline.component';
import { BulkLoadUploadComponent } from './bulk-load-upload/bulk-load-upload.component';
import { CSVBoxAngularModule } from '@csvbox/angular_8';
import { RemarksComponent } from './bulk-load-upload/remarks/remarks.component';
import { ArchivedWellsComponent } from './wells/archived-wells/archived-wells.component';
import { ImportedOrdersComponent } from './bulk-load-upload/imported-orders/imported-orders.component';
import { BulkBolUploadComponent } from './bulk-load-upload/bulk-bol-upload/bulk-bol-upload.component';
import { BulkBolUploadLogComponent } from './bulk-load-upload/bulk-bol-upload-log/bulk-bol-upload-log.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DialogComponent,
    ConfirmDialogComponent,
    ConfirmUpdateStageDialogComponent,
    WellComponent,
    WellCreationComponent,
    MineCreationComponent,
    StorageCreationComponent,
    MineSelectionComponent,
    MapComponent,
    SettingsComponent,
    SignupComponent,
    ResetPasswordComponent,
    DriverSettingsComponent,
    UserSettingsComponent,
    InviteComponent,
    CompletedOrdersComponent,
    CompletedOrderDetailComponent,
    OrderTableComponent,
    TruckingComponent,
    TruckingListTableComponent,
    VendorContractEditComponent,
    VendorContractCreationComponent,
    TicketDialogComponent,
    CancelDialogComponent,
    EmailSettingsComponent,
    EmailDetailsComponent,
    EmailDetailsV2Component,
    EmailFormComponent,
    EmailFormV2Component,
    EmailSettingsV2Component,
    ClusterChoiceDialogComponent,
    MinesComponent,
    ReasonDialogComponent,
    VersioningComponent,
    RunboardSummaryComponent,
    RunboardDriverOptionsComponent,
    NotificationPreferencesComponent,
    SafePipe,
    OnlyEnvDirective,
    StageUrlIndicatorComponent,
    UserGroupComponent,
    AddUserGroupComponent,
    UnsubscribeComponent,
    BulkExportComponent,
    FracCreationMapComponent,
    LogisticsRunboardSummaryComponent,
    LogisticsRunboardDriverOptionsComponent,
    DispatcherVideoTrainingComponent,
    EmailAnalyticsComponent,
    AddChargeDialogComponent,
    SigninWithCustomTokenComponent,
    OrderUniqueIdSearchComponent,
    DispatcherOrderLandingComponent,
    RtiDemoComponent,
    LoaderAnalyticsDashboardComponent,
    BillingOrdersComponent,
    AllFracOrderTableComponent,
    SwitchVendorContractComponent,
    ConfirmActionDialogComponent,
    UpdatePasswordComponent,
    SigninWithCustomTokenLohiComponent,
    DynamicPricingComponent,
    DynamicPricingDialogComponent,
    CrewsComponent,
    StorageSupplementalMeshUpdateComponent,
    DCStorageSupplementalMeshUpdateComponent,
    DistributionCenterCreationComponent,
    DistributionCenterStorageCreationComponent,
    LoaderAnalyticsDashboardV2Component,
    LoadsOnDateComponent,
    InTimezonePipe,
    MatchesForLoadComponent,
    UploadAnalyticsFileComponent,
    UnsubscribePoComponent,
    DynamicPricingForecastComponent,
    UploadGrGiFileComponent,
    LoadsForPurchaseOrderComponent,
    SubcarrierComponent,
    CarrierComponent,
    ExportLoaderAnalyticsDataComponent,
    CrewDataDashboardComponent,
    UploadCrewDataFileComponent,
    CrewDataOnDateComponent,
    LmoLoaderNameMappingComponent,
    LmoLoaderNameMappingFormComponent,
    LmoPurchaseOrderTimelineComponent,
    BulkLoadUploadComponent,
    RemarksComponent,
    ArchivedWellsComponent,
    ImportedOrdersComponent,
    BulkBolUploadComponent,
    BulkBolUploadLogComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    HttpClientModule,
    MaterialModule,
    FlexLayoutModule,
    UIComponentsModule,
    DispatcherModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    AngularFirestoreModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    PdfViewerModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: window.location.hostname.includes('sand.shaleapps.com'),
    }),
    DeviceDetectorModule.forRoot(),
    NgxFileDropModule,
    TooltipModule,
    LoggerModule.forRoot({
      enableSourceMaps: environment.production ? false : true,
      level: environment.production ? NgxLoggerLevel.ERROR : NgxLoggerLevel.DEBUG,
    }),
    NgxMaterialTimepickerModule,
    ChartModule,
    CSVBoxAngularModule,
  ],
  providers: [
    AuthGuard,
    AdminGuard,
    AuthService,
    CrudService,
    LocalStorageService,
    UserService,
    AccountApiService,
    UserApiService,
    MapService,
    SubnavService,
    SessionService,
    WaypointApiService,
    SiteApiService,
    GlobalSearchApiService,
    SessionApiService,
    MineApiService,
    WellApiService,
    ConstantsApiService,
    DatePipe,
    TooltipTimestampPipe,
    NumberDecimalPipe,
    OrderApiService,
    SearchApiService,
    TruckingApiService,
    StoreService,
    MapUtilService,
    FileUploadService,
    WellService,
    HelperService,
    FeatureFlagService,
    ConfigValuesService,
    SubcarrierApiService,
    {
      multi: true,
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
    {
      multi: true,
      provide: HTTP_INTERCEPTORS,
      useClass: CacheInterceptorService,
    },
    {
      multi: true,
      provide: HTTP_INTERCEPTORS,
      useClass: ValidateRouteService,
    },
    {
      multi: true,
      provide: HTTP_INTERCEPTORS,
      useClass: VersionHeaderInterceptorService,
    },
    {
      multi: true,
      provide: HTTP_INTERCEPTORS,
      useClass: StartedAtInterceptorService,
    },
    {
      provide: RouteReuseStrategy,
      useClass: CustomRouteReuseStrategy,
    },
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    StorageCreationComponent,
    BulkDeclineDialogComponent,
    CancelDialogComponent,
    ReasonDialogComponent,
    TicketDialogComponent,
    DeclineDialogComponent,
    ReassignDialogComponent,
    MineCreationComponent,
    MineSelectionComponent,
    ChoiceDialogComponent,
    InviteComponent,
    AddUserGroupComponent,
    DialogComponent,
    ConfirmDialogComponent,
    ConfirmUpdateStageDialogComponent,
    EmailFormComponent,
    EmailFormV2Component,
    ClusterChoiceDialogComponent,
    RunboardDriverOptionsComponent,
    ReassignTruckComponent,
    AddChargeDialogComponent,
    OrderUniqueIdSearchComponent,
    SwitchVendorContractComponent,
    ConfirmActionDialogComponent,
    DynamicPricingDialogComponent,
  ],
})
export class AppModule {}
