import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BulkBolUploadLog } from '../bulk-load-upload.model';

@Component({
  selector: 'sa-bulk-bol-upload-log',
  templateUrl: './bulk-bol-upload-log.component.html',
  styleUrls: ['./bulk-bol-upload-log.component.scss'],
})
export class BulkBolUploadLogComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

  dataSource: BulkBolUploadLog[];
  displayedColumns: string[] = [
    'id',
    'fracName',
    'filename',
    'bolNumber',
    'matchingOrderId',
    'remarks',
    'date',
    'uploadedUserName',
    'status',
  ];

  ngOnInit(): void {
    this.dataSource = this.data;
  }
}
