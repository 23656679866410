import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileSize',
})
export class FileSizePipe implements PipeTransform {
  transform(value: any): any {
    if (value < 1000) {
      return (value / (1024 * 1024)).toFixed(6) + ' bytes';
    } else if (value < 1000000) {
      return (value / (1024 * 1024)).toFixed(4) + ' KB';
    } else if (value < 1000000000) {
      return (value / (1024 * 1024)).toFixed(4) + ' MB';
    }
  }
}
