<sa-top-nav></sa-top-nav>

<div class="p-4">
  <div class="flex flex-row">
    <div class="flex-auto">
      <div class="flex-initial">
        <h2>Imported Orders</h2>
      </div>

      <div class="flex-initial">
        <h3>List of imported orders from import {{ importID }}.</h3>
      </div>
    </div>
    <div class="flex-initial justify-items-end content-center">
      <button mat-flat-button color="primary" (click)="downloadCsv()" target="_blank">
        <mat-icon>download</mat-icon>
        Export
      </button>
    </div>
  </div>

  <table mat-table [dataSource]="dataSource" class="my-3 overflow-scroll">
    <ng-container matColumnDef="order_id">
      <th mat-header-cell *matHeaderCellDef>Order ID</th>
      <td mat-cell *matCellDef="let element">
        <a [routerLink]="['/invoicing/order', element.order_id]">{{ element.order_id }} </a>
      </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>Status</th>
      <td mat-cell *matCellDef="let element">{{ element.status }}</td>
    </ng-container>

    <ng-container matColumnDef="truck_number">
      <th mat-header-cell *matHeaderCellDef>Truck number</th>
      <td mat-cell *matCellDef="let element">{{ element.truck_number }}</td>
    </ng-container>

    <ng-container matColumnDef="trailer">
      <th mat-header-cell *matHeaderCellDef>Trailer</th>
      <td mat-cell *matCellDef="let element">{{ element.trailer }}</td>
    </ng-container>

    <ng-container matColumnDef="driver_phone_number">
      <th mat-header-cell *matHeaderCellDef>Driver phone no.</th>
      <td mat-cell *matCellDef="let element">{{ element.driver_phone_number }}</td>
    </ng-container>

    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef>Date</th>
      <td mat-cell *matCellDef="let element">{{ element.date }}</td>
    </ng-container>

    <ng-container matColumnDef="sandbox_1">
      <th mat-header-cell *matHeaderCellDef>Sandbox 1</th>
      <td mat-cell *matCellDef="let element">{{ element.sandbox_1 }}</td>
    </ng-container>

    <ng-container matColumnDef="sandbox_2">
      <th mat-header-cell *matHeaderCellDef>Sandbox 2</th>
      <td mat-cell *matCellDef="let element">{{ element.sandbox_2 }}</td>
    </ng-container>

    <ng-container matColumnDef="load_weight">
      <th mat-header-cell *matHeaderCellDef>Load weight</th>
      <td mat-cell *matCellDef="let element">{{ element.load_weight }}</td>
    </ng-container>

    <ng-container matColumnDef="load">
      <th mat-header-cell *matHeaderCellDef>Load</th>
      <td mat-cell *matCellDef="let element">{{ element.load }}</td>
    </ng-container>

    <ng-container matColumnDef="sand_type">
      <th mat-header-cell *matHeaderCellDef>Sand type</th>
      <td mat-cell *matCellDef="let element">{{ element.sand_type }}</td>
    </ng-container>

    <ng-container matColumnDef="loaded_time">
      <th mat-header-cell *matHeaderCellDef>Loaded time</th>
      <td mat-cell *matCellDef="let element">{{ element.loaded_time }}</td>
    </ng-container>

    <ng-container matColumnDef="eta_txtime">
      <th mat-header-cell *matHeaderCellDef>ETA TX Time</th>
      <td mat-cell *matCellDef="let element">{{ element.eta_txtime }}</td>
    </ng-container>

    <ng-container matColumnDef="time_arrival">
      <th mat-header-cell *matHeaderCellDef>Time arrival</th>
      <td mat-cell *matCellDef="let element">{{ element.time_arrival }}</td>
    </ng-container>

    <ng-container matColumnDef="stage">
      <th mat-header-cell *matHeaderCellDef>Stage</th>
      <td mat-cell *matCellDef="let element">{{ element.stage }}</td>
    </ng-container>

    <ng-container matColumnDef="start_date">
      <th mat-header-cell *matHeaderCellDef>Start date</th>
      <td mat-cell *matCellDef="let element">{{ element.start_date }}</td>
    </ng-container>

    <ng-container matColumnDef="end_date">
      <th mat-header-cell *matHeaderCellDef>End date</th>
      <td mat-cell *matCellDef="let element">{{ element.end_date }}</td>
    </ng-container>

    <ng-container matColumnDef="bol_ticket">
      <th mat-header-cell *matHeaderCellDef>BOL Ticket</th>
      <td mat-cell *matCellDef="let element">{{ element.bol_ticket }}</td>
    </ng-container>

    <ng-container matColumnDef="box_ids">
      <th mat-header-cell *matHeaderCellDef>Box IDs</th>
      <td mat-cell *matCellDef="let element">{{ element.box_ids }}</td>
    </ng-container>

    <ng-container matColumnDef="facility">
      <th mat-header-cell *matHeaderCellDef>Facility</th>
      <td mat-cell *matCellDef="let element">{{ element.facility }}</td>
    </ng-container>

    <ng-container matColumnDef="frac_name">
      <th mat-header-cell *matHeaderCellDef>Frac</th>
      <td mat-cell *matCellDef="let element">{{ element.frac_name }}</td>
    </ng-container>

    <ng-container matColumnDef="po_number">
      <th mat-header-cell *matHeaderCellDef>PO number</th>
      <td mat-cell *matCellDef="let element">{{ element.po_number }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
