import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from '~services/user.service';
import { environment } from '~environments/environment';
import {
  BulkLoadUploadPayload,
  BulkLoadUploadRowData,
  ImportLog,
  ImportLogResponse,
  UserPayload,
} from './bulk-load-upload.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { RemarksComponent } from './remarks/remarks.component';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { MatPaginator } from '@angular/material/paginator';
import { Subscription } from 'rxjs';
import { share } from 'rxjs/operators';
import { FeatureFlagService } from '~services/feature-flag.service';
import { SubnavMenu } from '~models/subnav-menu';

type SubnavLabel = 'Load' | 'BOL Upload';

const SubnavLabels = {
  load: 'Load' as SubnavLabel,
  bolUpload: 'BOL Upload' as SubnavLabel,
};

@Component({
  selector: 'sa-bulk-load-upload',
  templateUrl: './bulk-load-upload.component.html',
  styleUrls: ['./bulk-load-upload.component.scss'],
})
export class BulkLoadUploadComponent implements OnInit, AfterViewInit {
  public loading: boolean;
  licenseKey: string = environment.cvsBox.licenceKey;
  displayedColumns: string[] = ['id', 'filename', 'date', 'status'];
  dataSource: ImportLog[];
  user: UserPayload;
  total: number;
  private importCall: Subscription;

  subnavLabels = SubnavLabels;
  subnavMenu: SubnavMenu[] = [
    { label: this.subnavLabels.load, active: true },
    { label: this.subnavLabels.bolUpload, active: false },
  ];
  activeMenu = this.subnavLabels.load;

  constructor(
    private http: HttpClient,
    private userService: UserService,
    private snackBar: MatSnackBar,
    private matDialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private featureFlagService: FeatureFlagService,
  ) {
    this.featureFlagService.isFlagActive('showBulkLoadUploadMenu').subscribe((isActive) => {
      if (isActive === false) {
        window.location.href = '/';
      }
    });
  }

  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngOnInit(): void {
    this.loading = false;
    this.user = {
      user_id: this.userService.userId(),
      account_id: this.userService.accountId(),
    };
  }

  ngAfterViewInit(): void {
    this.fetchLogs();

    this.paginator.page.subscribe((pageData) => {
      this.fetchLogs();
    });
  }

  onData(result: boolean, data: any) {
    if (result) {
      const payload: BulkLoadUploadPayload[] = this.prepPayload(data);
      this.importLoads(payload);
    } else {
      this.snackBar.open('There was some problem uploading the sheet', null, {
        duration: 10000,
      });
    }
  }

  showRemarks(log: ImportLog) {
    let data: string[];
    if (log.remarks) {
      data = log.remarks.split('\n');
    } else {
      data = ['Processing'];
    }

    const config = { data: data, maxHeight: '80vh', maxWidth: '600px', width: '80%', disableClose: false };
    this.matDialog.open(RemarksComponent, config);
  }

  fetchLogs() {
    this.loading = true;
    this.cdr.detectChanges();
    this.http
      .get<ImportLogResponse>(`${environment.api}/bulk_load_upload_logs`, {
        params: {
          page: `${this.paginator.pageIndex}`,
          pageSize: `${this.paginator.pageSize}`,
        },
      })
      .subscribe(
        (data: ImportLogResponse) => {
          this.dataSource = data.data;
          this.total = data.total;
          this.loading = false;
        },
        (logs) => {
          this.loading = false;
          console.error('Failed to get updated load logs', logs);
        },
      );
  }

  importLoads(payload: BulkLoadUploadPayload[]) {
    this.loading = true;
    this.importCall?.unsubscribe();
    this.importCall = this.http
      .post<BulkLoadUploadPayload[]>(`${environment.api}/orders/bulk`, payload, { observe: 'response' })
      .pipe(share())
      .subscribe(
        (response: HttpResponse<any>) => {
          if (response.status === 206) {
            const lockDuration = response.body.lockDuration;
            this.snackBar.open(`Please wait ${lockDuration} minutes, then upload the file again.`, null, {
              duration: 10000,
              panelClass: ['snackbar-error'],
            });
          } else {
            this.snackBar.open('Uploaded', null, {
              duration: 10000,
            });
          }
          this.fetchLogs();
          this.loading = false;
          this.importCall?.unsubscribe();
        },
        (error) => {
          this.loading = false;
          this.fetchLogs();
          this.importCall?.unsubscribe();
        },
      );
  }

  prepPayload(data: any): BulkLoadUploadPayload[] {
    const payload: BulkLoadUploadPayload[] = [];
    const customField: UserPayload = {
      user_id: Number(data.custom_fields.user_id),
      account_id: Number(data.custom_fields.account_id),
    };

    data.rows.forEach(function(row, index) {
      let sheetRow: BulkLoadUploadRowData;
      sheetRow = {
        order_id: row.order_id,
        status: row.status,
        truck_number: row.truck_number,
        trailer: row.trailer,
        driver_phone_number: row.driver_phone_number,
        date: row.date,
        load_weight: row.load_weight,
        load: row.load,
        sandbox_1: row.sandbox_1,
        sandbox_2: row.sandbox_2,
        sand_type: row.sand_type,
        loaded_time: row.loaded_time,
        eta_txtime: row.eta_txtime,
        time_arrival: row.time_arrival,
        stage: row.stage,
        start_date: row.start_date,
        end_date: row.end_date,
        bol_ticket: row.bol_ticket,
        box_ids: row.box_ids,
        facility: row.facility,
        frac_name: row.frac_name,
        po_number: row.po_number,
        carrier: row.carrier,
      };

      let rowData: BulkLoadUploadPayload;
      rowData = {
        import_id: 0,
        sheet_id: Number(data.sheet_id),
        sheet_name: data.sheet_name,
        row_number: index + 1,
        total_rows: data.row_count,
        env_name: data.env_name,
        original_filename: data.original_filename,
        row_data: sheetRow,
        custom_fields: customField,
      };
      payload.push(rowData);
    });
    return payload;
  }
}
