import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BulkLoadUploadRowData } from '../bulk-load-upload.model';
import { HttpClient } from '@angular/common/http';
import { environment } from '~environments/environment';

@Component({
  selector: 'sa-imported-orders',
  templateUrl: './imported-orders.component.html',
  styleUrls: ['./imported-orders.component.scss'],
})
export class ImportedOrdersComponent implements OnInit {
  public dataSource: BulkLoadUploadRowData[];
  public displayedColumns: string[] = [
    'order_id',
    'status',
    'truck_number',
    'trailer',
    'driver_phone_number',
    'date',
    'sandbox_1',
    'sandbox_2',
    'load_weight',
    'load',
    'sand_type',
    'loaded_time',
    'eta_txtime',
    'time_arrival',
    'stage',
    'start_date',
    'end_date',
    'bol_ticket',
    'box_ids',
    'facility',
    'frac_name',
    'po_number',
  ];
  public importID: number;

  constructor(private http: HttpClient, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.importID = this.route.snapshot.params['bulkLoadUploadLogId'];
    this.http
      .get<BulkLoadUploadRowData[]>(`${environment.api}/bulk_load_uploaded`, {
        params: {
          importID: `${this.importID}`,
        },
      })
      .subscribe((response: BulkLoadUploadRowData[]) => {
        this.dataSource = response;
      });
  }

  async downloadCsv() {
    let blob: Blob;
    let url: any;
    const data: any = await this.http
      .get(`${environment.api}/bulk_load_uploaded_csv`, {
        params: { importID: `${this.importID}` },
        responseType: 'text',
      })
      .toPromise();

    const a = document.createElement('a');
    document.body.appendChild(a);
    (blob = new Blob([data], { type: 'text/csv' })), (url = window.URL.createObjectURL(blob));
    a.href = url;
    a.download = `uploaded_${this.importID}`;
    a.click();
    window.URL.revokeObjectURL(url);
  }
}
