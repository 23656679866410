<div>
  <table mat-table [dataSource]="dataSource" class="bol-upload-log-table">
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef>ID</th>
      <td mat-cell *matCellDef="let element">{{ element.id }}</td>
    </ng-container>

    <ng-container matColumnDef="fracName">
      <th mat-header-cell *matHeaderCellDef>Frac</th>
      <td mat-cell *matCellDef="let element">{{ element.fracName }}</td>
    </ng-container>

    <ng-container matColumnDef="filename">
      <th mat-header-cell *matHeaderCellDef>Filename</th>
      <td mat-cell *matCellDef="let element">{{ element.filename }}</td>
    </ng-container>

    <ng-container matColumnDef="bolNumber">
      <th mat-header-cell *matHeaderCellDef>BOL Number</th>
      <td mat-cell *matCellDef="let element" class="font-bold">{{ element.bolNumber }}</td>
    </ng-container>

    <ng-container matColumnDef="matchingOrderId">
      <th mat-header-cell *matHeaderCellDef>Matched Order Id</th>
      <td mat-cell *matCellDef="let element" class="font-bold">
        {{ element.matchingOrderId ? (element.matchingOrderId | sPrefix) : '' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="remarks">
      <th mat-header-cell *matHeaderCellDef>Remarks</th>
      <td mat-cell *matCellDef="let element">{{ element.remarks }}</td>
    </ng-container>

    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef>Date</th>
      <td mat-cell *matCellDef="let element">
        {{ element.createdAt | date: 'MMM d, y, h:mm a' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="uploadedUserName">
      <th mat-header-cell *matHeaderCellDef>Uploaded By</th>
      <td mat-cell *matCellDef="let element">{{ element.uploadedUserName }}</td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>Status</th>
      <td mat-cell *matCellDef="let element" class="text-right font-bold">
        {{ element.status | titlecase }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
